import React from 'react';
import Slider from 'react-slick';
import '../styles/Home.css';

const Home = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <section id="home" className="home">
      <div className="slider-container">
        <Slider {...settings}>
          <div>
            <img src="ad1.png" alt="Slide 1" className="slider-image" />
          </div>
          <div>
            <img src="ad2.png" alt="Slide 2" className="slider-image" />
          </div>
        </Slider>
      </div>
      <h1>Merigold Care</h1>
      <p>Your one-stop platform for your safe and secure online medical purchases</p>
      <button onClick={() => document.getElementById('products').scrollIntoView()}>See Products</button>
      <img src="home.png" alt="Header" className="section-image" />
    </section>
  );
};

export default Home;
