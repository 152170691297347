import React, { useState } from 'react';
import '../styles/Navbar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  const closeMenu = () => {
    setMenuOpen(false);
  }

  return (
    <nav className="navbar">
      <div className="logo"><a href="#home">Merigold<span>Care</span></a></div>
      <div className={`burger-menu ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <ul className={`nav-menu ${menuOpen ? 'open' : ''}`}>
      <li><a href="#home" onClick={closeMenu}>Home</a></li>
        <li><a href="#products" onClick={closeMenu}>Products</a></li>
        <li><a href="#about" onClick={closeMenu}>About</a></li>
        <li><a href="#contact" onClick={closeMenu}>Contact</a></li>
      </ul>
    </nav>
  );
}

export default Navbar;
