import React from 'react';
import '../styles/About.css';

const About = () => {
  return (
    <section id="about" className="about">
      <div className="about-content">
        <img src="about-m.jpeg" alt="About us" className="background-img" />
        <div className="text-content">
          <h4>About Us</h4>
          <h2>We are a tech company with a platform that provides an integrated gateway for doctors, patients, and dispensary pharmacies.</h2>
          <h3>Merigold is your hassle-free, one-stop platform for your safe and secure online purchases; ensuring seamless processing of all your needs for high quality medication at the lowest prices.</h3>
          <div className="features">
            <div className="feature">
              <i class='bx bxs-truck'></i>
              <p><b>Fast Delivery:</b> Purchase today and get your products within 24-48 hours from the time our fulfilment team shipped out your orders.</p>
            </div>
            <div className="feature">
              <i className="bx bx-lock-alt"></i>
              <p><b>Secured Shopping:</b> We make sure that all transactions are private and protected. The information provided through the checkout process is well-secured using cutting edge encryption technology on our website.</p>
            </div>
            <div className="feature">
              <i className="bx bx-support"></i>
              <p><b>Customer Support:</b> From providing top-quality products for our customers, we also provide exceptional customer support with our trained Order Fulfillment Specialists by giving assistance from planning, to ordering, consumption, and disposal.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
