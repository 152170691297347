import React, { useEffect, useState } from 'react';
import '../styles/Products.css';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState({});

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/api/v1/init`)
      .then(response => response.json())
      .then(data => {
        setProducts(data.featured);
        const initialSelectedVariants = data.featured.reduce((acc, product) => {
          acc[product.id] = product.variants[0];
          return acc;
        }, {});
        setSelectedVariants(initialSelectedVariants);
      });
  }, []);

  const handleVariantChange = (product, variantId) => {
    const variant = product.variants.find(v => v.id === variantId);
    setSelectedVariants(prevState => ({
      ...prevState,
      [product.id]: variant
    }));
  };

  return (
    <section id="products" className="products">
      <h2>Our Products</h2>
      <div className="product-container">
        <div className="product-cards">
          {products.map(product => (
            <div key={product.id} className="product-card">
              <div className="upper-part">
                <img src={`${process.env.REACT_APP_API_URL}${product.images[0]}`} alt={product.name} className="product-image"/>
                <div className="product-info">
                  <h3>{product.name}</h3>
                  <p>${selectedVariants[product.id]?.price || product.price}</p>
                  <div className="product-controls">
                    <select onChange={(e) => handleVariantChange(product, e.target.value)}>
                      {product.variants.map(variant => (
                        <option key={variant.id} value={variant.id}>
                          {variant.count} tablet/ {variant.dosage}
                        </option>
                      ))}
                    </select>
                    <button className="contact-btn" onClick={() => document.getElementById('contact').scrollIntoView()}>
                      Contact Us
                    </button>
                  </div>
                </div>
              </div>
              <div className="lower-part">
                <p className="product-description" dangerouslySetInnerHTML={{ __html: product.description }}></p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Products;
