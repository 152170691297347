import React from 'react';
import '../styles/Contact.css';

const Contact = () => {
  return (
    <section id="contact" className="contact">
      <h2>Contact Us</h2>
      <form>
        <label>First Name</label>
        <input type="text" name="firstName" required />
        <label>Last Name</label>
        <input type="text" name="lastName" required />
        <label>Email Address</label>
        <input type="email" name="email" required />
        <label>Phone Number</label>
        <input type="tel" name="phone" required />
        <label>Subject</label>
        <input type="text" name="subject" required />
        <label>Message</label>
        <textarea name="message" required></textarea>
        <button type="submit">Submit</button>
      </form>
    </section>
  );
}

export default Contact;
