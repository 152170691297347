import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Products from './components/Products';
import About from './components/About';
import Contact from './components/Contact';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <main>
        <Home />
        <Products />
        <About />
        <Contact />
      </main>
      <footer>
        <div className="footer-content">
          <div className="contact-info">
            <p><strong>CONTACT US</strong></p>
            <p><i className='bx bx-home'></i> 1007 N Orange St. 4th Floor 2692 Wilmington, DE 19801 United States</p>
            <p><i className='bx bx-envelope'></i> support@merigold.care</p>
            <p><i className='bx bx-phone'></i> +1-888-598-1651 | 3022235619</p>
          </div>
          <div className="terms-info">
            <p><strong>TERMS</strong></p>
            <p><a href="#home">Terms and Conditions</a></p>
            <p><a href="#home">Legal Disclaimer</a></p>
            <p><a href="#home">Privacy Policy</a></p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
